import { Injectable } from '@angular/core';
import {MatLegacySnackBar} from '@angular/material/legacy-snack-bar';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {
  constructor(private snackbar: MatLegacySnackBar) { }

  displayErrorMessage(err: HttpErrorResponse) {
    if (err?.status === 403) {
      this.snackbar.open('You have been logged out for inactivity, please login again', 'Ok', { panelClass: 'logout-snackbar'});
    } else {
      this.snackbar.open('There was an error handling your request. If this error continues to happen contact dezphonicsdev@gmail.com', 'Ok', { panelClass: 'error-snackbar'});
    }
  }
}
